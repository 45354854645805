(function () {
  // Function to initialize the image focus animation
  function initializeImageFocus() {
    console.log("initializeImageFocus is running!"); // Debug message

    const desktopMovement = 20;
    const blur = "3px";
    let animationInitialized = false;

    // Function to check if any input device can hover
    function canHover() {
      return window.matchMedia('(hover: hover)').matches;
    }

    function initializeAnimation() {
      if (!animationInitialized && canHover()) {
        animationInitialized = true;

        // Set blur as a CSS custom property
        document.querySelectorAll('section[id*="image-focus"] .image-block-outer-wrapper').forEach(wrapper => {
          wrapper.style.setProperty('--blur', blur);
        });

        // Add animation to images
        document.querySelectorAll('section[id*="image-focus"] .image-block .sqs-block-content').forEach(image => {
          let mouseX = 0, mouseY = 0;
          let imageX = 0, imageY = 0;
          const dampening = 0.1;
          const movementAmount = desktopMovement;
          let rect = image.getBoundingClientRect();

          // Handle mouse movement
          image.closest('section').addEventListener('mousemove', (e) => {
            rect = image.getBoundingClientRect();
            mouseX = e.clientX - rect.left;
            mouseY = e.clientY - rect.top;
          });

          // Reset animation on mouse leave
          image.closest('section').addEventListener('mouseleave', () => {
            mouseX = imageX = 0;
            mouseY = imageY = 0;
          });

          function updateImagePosition() {
            const targetX = -(mouseX / rect.width) * movementAmount + (movementAmount / 2);
            const targetY = -(mouseY / rect.height) * movementAmount + (movementAmount / 2);

            imageX += (targetX - imageX) * dampening;
            imageY += (targetY - imageY) * dampening;

            image.style.transform = `translate(${imageX}px, ${imageY}px)`;
            requestAnimationFrame(updateImagePosition);
          }

          requestAnimationFrame(updateImagePosition);
        });
      }
    }

    // Initialize animation
    initializeAnimation();
  }

  // Automatically run the function
  initializeImageFocus();
})();
